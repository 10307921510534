var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "试卷基本信息",
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
        on: {
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
      _c("head-layout", { attrs: { "head-title": "试题" } }),
      _c("grid-layout", {
        ref: "gridLayTableTopic",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableTopicData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.tableOnLoad,
          "page-size-change": _vm.tableOnLoad,
          "page-refresh-change": _vm.tableOnLoad,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }